import React, { useContext } from 'react';
import PrivateRoute from '../../libs/PrivateRoute';
import globalLinks from '../../config/links';
import ProfileBillingAccounts from '../../pages/ProfileBillingAccounts';
import BankAccount from '../../pages/BankAccount';
import PaypalAccount from '../../pages/PaypalAccount';
import InvoicesList from '../../pages/InvoicesList';
import Invoice from '../../pages/Invoice';
import ProfilesList from '../../pages/ProfilesList';
import Profile from '../../pages/Profile';
import { CustomRoutesContainerProps } from '../../types/shared';
import ManageAdmins from '../../pages/ManageAdmins';
import AuthContext from '../../context/auth';
import { renderRouteIfAllowedTo } from '../../utils/ui/capabilities';

export default function ProfileRoutes({ auth }: CustomRoutesContainerProps) {
  const { userCapabilities } = useContext(AuthContext);

  return (
    <>
      {
        renderRouteIfAllowedTo(
          userCapabilities,
          ["manage_profiles"],
          <>
            <PrivateRoute path={globalLinks.account.profiles} auth={auth}>
              <ProfilesList />
            </PrivateRoute>
            <PrivateRoute path={`${globalLinks.account.profile}/:id`} auth={auth}>
              <Profile />
            </PrivateRoute>
            <PrivateRoute exact path={globalLinks.account.billing.userBilling} auth={auth}>
              <ProfileBillingAccounts />
            </PrivateRoute>
            <PrivateRoute path={globalLinks.account.billing.bankAccount} auth={auth}>
              <BankAccount />
            </PrivateRoute>
            <PrivateRoute path={globalLinks.account.billing.paypal} auth={auth}>
              <PaypalAccount />
            </PrivateRoute>
            <PrivateRoute path={globalLinks.account.invoice} auth={auth}>
              <Invoice />
            </PrivateRoute>
            <PrivateRoute
              path={[
                globalLinks.account.userInvoices,
                globalLinks.account.invoices
              ]}
              auth={auth}>
              <InvoicesList apiUrl={globalLinks.api.account.invoices} />
            </PrivateRoute>
          </>
        )
      }
      {
        renderRouteIfAllowedTo(
          userCapabilities,
          ["edit_admins", "delete_admins", "read_admins"],
          <PrivateRoute path={globalLinks.account.manageAdmins.root} auth={auth}>
            <ManageAdmins />
          </PrivateRoute>
        )
      }
    </>
  )
}
