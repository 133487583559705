import React from 'react';
import Col from 'react-bootstrap/Col';
import FormField from '../../components/FormField';
import GradientButton from '../../components/GradientButton';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import uiTexts from '../../config/text';
import { BillingFormProps } from '../../types/shared';
import countriesByContinents from '../../config/data/countriesByContinents';

const bankText = uiTexts.account.billing.bank;
export default function index({ onSubmit, onChange, state, onClose, readonly = false }: BillingFormProps) {
  return (
    <Form onSubmit={onSubmit}>

      <Form.Row>
        <Col sm={12}>
          {
            !!state.errors.global &&
            <Alert className='w-100' variant='danger' onClose={onClose && onClose('ERROR', {})} dismissible transition>{state.errors.global}</Alert>
          }
          {
            !!state.global &&
            <Alert className='w-100' variant='success' onClose={onClose && onClose('SUCCESS', null)} dismissible transition>{state.global}</Alert>
          }
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} sm={6}>
          <FormField defaultValue={state.data?.owner} error={state.errors?.owner || null} onChange={onChange} label={bankText.owner} name='owner' required autoFocus readonly={readonly} />
        </Col>
        <Col xs={12} sm={6}>
          <FormField defaultValue={state.data?.bankName} error={state.errors?.bankName || null} onChange={onChange} label={bankText.name} name='bankName' required readonly={readonly} />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} sm={6}>
          <Form.Group className='mb-2' controlId='holderCountry'>
            <Form.Label className='mb-1'>{bankText.country}</Form.Label>
            <Form.Control size='lg' onChange={onChange} name='holderCountry' as="select" className='pt-2' defaultValue={state.data?.holderCountry || '-1'} isInvalid={!!state.errors?.holderCountry} disabled={readonly} required>
              <option value='-1' disabled>{uiTexts.account.profil.country}</option>
              {
                Object.keys(countriesByContinents).map((continent: string) => {
                  return countriesByContinents[continent].map((country: string) => (
                    <option key={country} value={country}>{country}</option>
                  ));
                })
              }
            </Form.Control>
            {
              !!state.errors?.holderCountry &&
              <Form.Control.Feedback type="invalid">
                {state.errors?.holderCountry}
              </Form.Control.Feedback>
            }
          </Form.Group>
        </Col>
        <Col xs={12} sm={6}>
          <Form.Group className='mb-2' controlId='bankCountry'>
            <Form.Label className='mb-1'>{bankText.bankCountry}</Form.Label>
            <Form.Control size='lg' onChange={onChange} name='bankCountry' as="select" className='pt-2' defaultValue={state.data?.bankCountry || '-1'} isInvalid={!!state.errors?.bankCountry} disabled={readonly} required>
              <option value='-1' disabled>{uiTexts.account.profil.country}</option>
              {
                Object.keys(countriesByContinents).map((continent: string) => {
                  return countriesByContinents[continent].map((country: string) => (
                    <option key={country} value={country}>{country}</option>
                  ));
                })
              }
            </Form.Control>
            {
              !!state.errors?.bankCountry &&
              <Form.Control.Feedback type="invalid">
                {state.errors?.bankCountry}
              </Form.Control.Feedback>
            }
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} sm={6}>
          <FormField defaultValue={state.data?.holderAddress} error={state.errors?.holderAddress || null} onChange={onChange} label={bankText.holderAddress} name='holderAddress' required readonly={readonly} />
        </Col>
        <Col xs={12} sm={6}>
          <FormField defaultValue={state.data?.bankAddress} error={state.errors?.bankAddress || null} onChange={onChange} label={bankText.bankAddress} name='bankAddress' required readonly={readonly} />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} sm={6}>
          <FormField defaultValue={state.data?.holderAddress2} onChange={onChange} label={bankText.holderAddress2} name='holderAddress2' readonly={readonly} />
        </Col>
        <Col xs={12} sm={6}>
          <FormField defaultValue={state.data?.bankAddress2} onChange={onChange} label={bankText.bankAddress2} name='bankAddress2' readonly={readonly} />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} sm={6}>
          <FormField defaultValue={state.data?.holderCity} error={state.errors?.holderCity || null} onChange={onChange} label={bankText.holderCity} name='holderCity' required readonly={readonly} />
        </Col>
        <Col xs={12} sm={6}>
          <FormField defaultValue={state.data?.bankCity} error={state.errors?.bankCity || null} onChange={onChange} label={bankText.bankCity} name='bankCity' required readonly={readonly} />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} sm={6}>
          <FormField defaultValue={state.data?.holderPostcode} error={state.errors?.holderPostcode || null} onChange={onChange} label={bankText.holderPostcode} name='holderPostcode' required readonly={readonly} />
        </Col>
        <Col xs={12} sm={6}>
          <FormField defaultValue={state.data?.bankZipCode} error={state.errors?.bankZipCode || null} onChange={onChange} label={bankText.bankZipCode} name='bankZipCode' required readonly={readonly} />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} sm={6} className='custom__form-group'>
          <FormField defaultValue={state.data?.iban} error={state.errors?.iban || null} onChange={onChange} label={bankText.iban} name='iban' required readonly={readonly} />
        </Col>
        <Col xs={12} sm={6} className='custom__form-group'>
          <FormField defaultValue={state.data?.swift} error={state.errors?.swift || null} onChange={onChange} label={bankText.swift} name='swift' required readonly={readonly} />
        </Col>
      </Form.Row>
      {
        onSubmit && (
          <div className="d-flex justify-content-sm-end justify-content-start">
            <GradientButton size='lg' disabled={state.loading || !!state.global} className='text-white pl-4 pr-4' type="submit">
              {
                state.loading
                  ? uiTexts.global.loading
                  : uiTexts.global.save
              }
            </GradientButton>
          </div>
        )
      }
    </Form>
  )
}
