import globalLinks from "./links";

const status: any = {
  PENDING: 'En attente',
  CANCEL: 'Annuler',
  DELIVRED: 'Livrée',
  PAID: 'Payée/Contrôle Qualité',
  CLOSED: 'Traitée',
  OPENED: 'En attente',
};
const payoutsTitle: any = {
  PENDING: 'Versement non réglé',
  PAID: 'Versement réglé',
};

const uiTexts = {
  signup: {
    form: {
      title: 'Dropzik',
      imageAlt: 'Dropzik app',
      fname: 'Nom',
      lname: 'Prénom',
      email: 'E-mail',
      password: 'Mot de passe',
      password_confirmation: 'Confirmation du Mot de passe',
      forget: 'Mot de passe oublié',
      button: 'Se connecter',
      signup: 'Créer un compte gratuitement',
      resetPassword: 'Réinitialisez le mot de passe',
      fullname: 'Nom complet'
    },
    messages: {
      errors: {
        fname: 'Le nom est requis(minimum 3 lettres)',
        lname: 'Le prénom est requis(minimum 3 lettres)',
        email: 'E-mail n\'est pas valide',
        password: 'Mot de passe est requis(minimum 6 caractères)',
        password_confirmation: 'Confirmation du Mot de passe doit être comme mot de passe',
        unauthorized: 'Réactualisez la page SVP',
        city: 'La ville est requise',
        country: 'Le pays est requis',
        postcode: 'Le code postal est requis',
        address: 'L’adresse est requise',
        income: 'Entrez un numéro',
        libraryIncomePercentile: 'Vérifie pourcentage plateformes entre 0-100',
        youtubeChannelIncomePercentile: 'Vérifie pourcentage Youtube entre 0-100',
        noValid: 'Entrez une valeur valide',
        status: 'Sélectionnez une valeur valide',
        state: 'Selectionnez l\'etat de compte soit activer/desactiver',
        role: 'Valuer inacceptable',
        memo: 'La valeur que vous avez saisi n\'est pas valide'
      }
    }
  },
  global: {
    network: 'Vérifiez votre connexion internet et ressayez',
    loading: 'Chargement..',
    signout: 'Se déconnecter',
    logo: 'Dropzik Inc',
    save: 'Sauvegarder',
    generate: 'Générer le contrat',
    cancel: 'Annuler',
    filter: 'filtrer',
    empty: 'Aucune donnée de paiement enregistrée.',
    close: 'Fermer',
    remove: 'Supprimer',
    total: 'Total',
    account: 'Compte',
    visit: 'Voir détails',
    edit: 'Modifier',
    emptyData: 'Passe une valeur non-vide',
    askConfirmation: 'Vous êtes sure?',
    sure: 'Oui, je suis sure',
    download: 'Télécharger',
    downloadCsv: 'Télécharger Excel',
    noOptions: 'Pas de données',
    agree: "J'accepte",
    pay: 'Payer',
    retry: 'Ressayez SVP',
    quantity: 'Quantité',
    status,
    itemStatus: 'État',
    userId: "ID de l'utilisateur",
    releaseTitle: "Titre de la sortie",
    releaseId: "ID de la sortie",
    inValid: 'Données ne sont pas valide',
    notes: {
      delete: 'Voulez-vous vraiment supprimer cette sortie?',
      deletePayout: 'Voulez-vous vraiment supprimer cette reclamation?'
    },
    longTimeAgo: 'Y a longtemps',
    add: 'Ajouter',
    order: "Commande",
    paymentState: "Etat de paiement",
    createdAt: "Date de creation",
    sidebar: {
      accounts: {
        root: 'Comptes',
        profiles: 'Profils',
        invoices: 'Factures',
        manageAdmins: {
          root: 'Gérer les adminis'
        }
      },
      payouts: {
        root: 'Comptabilité',
        pending: 'Non traité',
        paid: 'Traité',
        unpayedAccounts: 'Profiles non traité',
      },
      productions: {
        root: 'Production',
        pending: 'Non-payée',
        paid: 'Payée',
        delivered: 'Livrée',
        unpublishedReleases: 'Non publie'
      },
      reporting: {
        list: 'Tous',
        byPlatform: 'Platform Rapports',
        byYoutube: 'Youtube Rapports',
        platform: 'Créer Platforme',
        youtube: 'Créer Youtube',
      },
      library: 'Bibliothèque',
      releases: 'Sorties',
      tracks: 'Pistes',
      artists: 'Artistes',
      editors: 'Éditeurs',
      reports: 'Rapports',
      services: {
        root: 'Services',
        promoCode: {
          list: 'List des codes',
          add: 'Créer code promo'
        },
        smartlink: {
          pending: 'Smartlink en attent',
          enabled: 'Smartlink active',
        },
        subscription: {
          list: 'Abonnements'
        }
      },
      settings: 'Paramètres',
      contributors: 'Contributeurs',
      accountingStats: {
        root: 'Statistiques',
        platform: "Platformes",
        youtube: "Youtube"
      },
      tools: {
        root: "Outils",
        editIsrcOrBarcode: "Modifier ISRC/UPC",
        redundancy: "ISRC/UPC redondance",
        emptyIsrcBarcode: "ISRC/UPC vide",
        emptyIsrc: "ISRC vide",
        emptyBarcode: "UPC vide",
        isrcBarcodeRedundancy: "ISRC redondance",
        upcRedundancy: "UPC redondance",
        namingRedundancy: "Redondance des noms",
        tracksNamingRedundancy: "Pistes avec meme nom",
        librariesNamingRedundancy: "Sortie avec meme nom",
        ticket: {
          opened: "Ticket ouvert",
          closed: "Ticket ferme",
        }
      }
    },
  },
  home: {
    title: 'Dashboard',
    alert: {
      title: 'Information importante',
    },
    chart: 'Tendances',
    boxes: {
      accounts: {
        title: 'Liste des comptes',
        action: {
          label: 'Voir tous',
          url: globalLinks.account.profiles,
        },
        content: 'Voir tous les comptes disponible sur la plateforme'
      },
      releases: {
        title: 'Liste des sorties impayées',
        action: {
          label: 'Voir tous',
          url: globalLinks.library.releases.replace(":status", 'unpaid'),
        },
        content: 'Voir les sortie impayées'
      },
      payouts: {
        title: 'Liste des versements en attente',
        action: {
          label: 'Voir tous',
          url: `${globalLinks.account.payouts}pending`
        },
        content: 'Voir les réclamations de versement en attente'
      },
      reportings: {
        add: {
          title: 'Ajouter un rapport',
          action: {
            label: 'Créer',
            url: globalLinks.reporting.platform,
          },
          content: 'Créer un rapport de ventes'
        },
        all: {
          title: 'Liste des rapports',
          action: {
            label: 'Lister les rapports',
            url: globalLinks.reporting.list,
          },
          content: 'Voir les rapports'
        }
      },
      plans: {
        title: 'Plans de sortie',
        action: {
          label: 'En savoir plus',
          url: 'http://dropzik.com/plans'
        },
      },
      distribution: {
        title: 'Distribution',
        action: {
          label: 'Créer une sortie/s',
          url: globalLinks.library.newRelease.details,
        },
        content: 'Créez une sortie ici OU sélectionnez vos pistes depuis votre bibliothèque !'
      },
      cash: {
        title: 'Mes crédits',
        content: {
          credit: 'Crédit cash',
          promo: 'Crédit promotionnel',
        }
      },
      invoices: {
        title: 'Factures',
        action: {
          label: 'Cliquer ici pour consulter',
        },
        empty: 'Aucune facture impayée.',
        exist: 'Vous avez des factures impayées.'
      }
    }
  },
  account: {
    profil: {
      title: 'Modifiez Profil',
      company: 'Société',
      city: 'Ville*',
      tva: 'Numéro de TVA',
      postcode: 'Code postal*',
      country: 'Pays',
      address: 'Adresse*',
      address2: 'Complément d\'adresse',
      timezone: 'Fuseau Horaire',
      state: 'Etat(activer/desactiver)',
      isLabel: 'Est une label',
      memo: 'Mémo',
      income: 'Revenus',
      libraryIncomePercentile: 'Pourcentage Plateformes',
      youtubeChannelIncomePercentile: 'Pourcentage Youtube',
      referrals: {
        add: "Ajouter commission",
        referred: "Utilisateur parrainé",
        referrer: "Utilisateur référent",
        percentile: "Pourcentage",
        referralsList: "Liste des parrainés",
        deleteItem: "Voulez-vous vraiment supprimer cette référence"
      }
    },
    profilesList: {
      title: 'Liste des profiles',
    },
    billing: {
      main: {
        title: 'Données de paiement',
        addNewAccount: 'Ajouter un compte de paiement',
        noAccount: 'Aucune donnée de paiement enregistrée.',
        bankAccount: 'Compte Bancaire',
        paypal: 'PayPal'
      },
      bank: {
        title: 'Modifiez Compte Bancaire',
        owner: 'Titulaire du compte bancaire*',
        name: 'Nom de la banque*',
        country: 'Pays*',
        bankCountry: 'Pays de la banque*',
        holderAddress: 'Adresse du titulaire du compte*',
        bankAddress: 'Adresse du la banque*',
        holderAddress2: 'Complément d\'adresse',
        bankAddress2: 'Adresse de la banque ligne 2',
        holderCity: 'Ville du titulaire du compte*',
        bankCity: 'Ville de la banque*',
        holderPostcode: 'Code postale du titulaire du compte*',
        bankZipCode: 'Code postal de la banque*',
        iban: 'IBAN*',
        swift: 'BIC/SWIFT*',
      },
      paypal: {
        title: 'Modifiez Compte PayPal',
      }
    },
    invoices: {
      title: 'Récapitulatif factures',
      empty: 'Aucune facture',
      order: 'Commande numéro',
      barcode: 'Code-bar',
      invoice: 'Factures',
      plan: 'Plan',
      expressDeliveryPrice: 'Prix de la livraison express',
      labelFees: 'Prix du label premium',
      stripeId: "Id de la transaction Stripe"
    },
    payout: {
      title: 'Versement',
      titles: payoutsTitle,
      creditTitle: 'Mes crédits',
      creditCash: 'Crédit cash',
      creditNote: 'Il se peut que le montant final change en raison de variation du taux de change.',
      request: 'Réclamer un versement',
      requestNote: 'Veuillez renseigner votre compte bancaire ou PayPal dans l\'onglet "Coordonnées bancaires" afin de recevoir un versement.',
      amount: 'Montant',
      selectAccont: 'Sélectionnez un compte',
      payoutInfos: 'Information de versement',
      payoutAccount: 'Voir compte banque/PayPal',
    },
    messages: {
      errors: {
        owner: 'Titulaire du compte bancaire est requis',
        bankName: 'Nom du la banque est requis',
        ownerCountry: 'Pays du titulaire du compte est requis',
        bankCountry: 'Pays du la banque est requis',
        holderAddress: 'Adresse titulaire du compte est requise',
        bankAddress: 'Adresse du la banque est requise',
        holderCity: 'Ville du titulaire du compte est requise',
        bankCity: 'Ville de la banque est requise',
        holderPostcode: 'Code postale du titulaire du compte est requis',
        bankZipCode: 'Code postal de la banque est requis',
        iban: 'IBAN est requis',
        swift: 'BIC/SWIFT est requis',
        amount: 'Ajoutez un montant',
        account: 'Sélectionnez un compte(bancaire ou PayPal)'
      }
    },
    manageAdmins: {
      title: 'Gérer les administrateurs',
      list: 'Liste des admins',
      addAdmin: 'Ajouter un admin',
      deleteAdmin: 'Supprimer un admin',
      deleteAdminConfirmation: 'Voulez-vous vraiment supprimer cet admin?'
    }
  },
  libraries: {
    title: 'Liste des sorties',
    releaseNote: 'Vous pouvez gérer ici toutes vos sorties créées et distribuées.',
    genre: 'Genre',
    originalRealeaseDate: 'Date de sortie original',
    digitalReleaseDate: 'Date de sortie digitale',
    barcode: 'Code-barre',
    isrc: 'ISRC',
    tracksListTitle: 'Liste des pistes',
    artistsList: 'Liste des artistes',
    contributorsList: 'Liste des contributeurs',
    editorsList: 'Liste des éditeurs',
    tracksList: 'Liste des pistes',
    releaseInvoice: 'Facture de la sortie',
    contract: 'Contrat de Licence',
    newRelease: {
      details: 'Details de la sortie',
      distribution: 'Distribution',
      tracksReleaseTitle: 'Ajouter une nouvelle piste',
      addArtists: 'Ajouter un nouvel artiste',
      addEditors: 'Ajouter un nouveau éditeur',
      addContributors: 'Ajouter un nouveau contributeur',
      addMusicians: 'Ajouter un nouveau interprète',
      planTitle: 'Livraison de la sortie',
      next: 'Suivant',
    },
    unpublished: {
      title: 'Unpublished releases'
    }
  },
  reporting: {
    mainTitle: 'Rapport de revenus',
    downloads: 'Téléchargements',
    youtube: 'YouTube',
    streams: 'Streams',
    addTitle: 'Créer un rapport',
    addYoutubeTitle: 'Créer un rapport Youtube',
    automaticReport: 'Rapports automatique',
    csvReport: 'Rapports via csv',
    deleteReport: 'Supprimer les rapports csv',
  },
  services: {
    promoCode: {
      list: 'Liste des codes promo',
      add: 'Créer un nouveau code promo',
      code: 'Code promo',
      type: 'Type',
      dueDate: 'Date d\'experation',
      amount: 'Percentage',
      description: 'Description'
    },
    smartlink: {
      list: 'Liste des smartlink',
      link: 'lien',
    },
    subscriptions: {
      list: 'Liste des abonnements',
    }
  },
  accountingStats: {
    home: {
      title: 'Statistiques'
    }
  },
  contracts: {
    custom: {
      title: "Créer un contrat"
    },
    ca: {
      companyName: 'Dropzik Numérique Inc',
      address: '1395 Fleury Est #102.2, Montréal, Québec, H2C 1R7'
    },
    dz: {
      companyName: 'Eurl Dropzik Numérique',
      address: '04, Rue Mohamed Abdou, Bureau N18 El Mouradia, Alger'
    }
  },
  tools: {
    fileTypeLabel: 'Choisir isrc(par defaut)/barcode pour modifier isrc/barcode respectivement',
    ticket: {
      title: "Liste des tickets",
      edit: "Modifier le ticket",
    }
  }
}

export default uiTexts;