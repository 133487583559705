import React, { useContext } from 'react';
import {
  DollarSign,
  Disc,
  LogOut,
  Headphones,
  Home as DashboardIcon,
  List,
  Music,
  Settings,
  Sliders,
  Users,
  BarChart2,
  CheckCircle,
  XCircle,
  Activity,
  Youtube,
  FilePlus,
  ZapOff,
  Tool
} from 'react-feather';
import { NavLink, useHistory } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import Axios from 'axios';

import globalLinks from '../../config/links';
import Logo from '../../assets/images/dropzik-logo-h55.png';
import globalSizes from '../../config/sizes';
import uiTexts from '../../config/text';
import AuthContext from '../../context/auth';
import UIContext from '../../context/ui';
import { renderRouteIfAllowedTo, renderRouteIfOneOfExist } from '../../utils/ui/capabilities';

import './style.css';

export default function SideBarMenu() {
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const uiContext = useContext(UIContext);
  const userCapabilities = authContext.userCapabilities;
  /**
   * navigate to url link using history
   * @param url link to next page
   */
  const onNavigateTo = (url: string) => {
    return (evnt: React.MouseEvent<HTMLAnchorElement>) => {
      evnt.preventDefault();
      uiContext?.setMenuState(false);
      history.push(url);
    }
  }
  // sign out
  const onSignOut = async (evnt: React.MouseEvent<HTMLAnchorElement>) => {
    evnt.preventDefault();
    try {
      await Axios.get(globalLinks.api.signout);
      authContext.signOut();
    } catch (error) { }
    uiContext?.setMenuState(false);
  }
  // open/close mobile menu
  const onToggleMobileMenu = () => {
    uiContext.setMenuState(!uiContext.menuState);
  }
  return (
    <nav id="sidebarMenu" className={`col-md-3 d-md-block sidebar bg-white collapse p-0 ${uiContext.menuState ? 'show' : ''}`}>
      <div className="sidebar-sticky mt-5 mt-md-0">
        <div className="logo__block pt-3 px-3 pb-0 d-none d-md-block">
          <NavLink className="navbar-brand image__container shadow-none mr-0 p-0" to={globalLinks.home}>
            <Image src={Logo} height={55} thumbnail className='image__thumbnail border-0 p-0' alt={uiTexts.global.logo} />
          </NavLink>
        </div>
        <ul className="nav flex-column p-3">
          <li className="nav-item">
            <NavLink exact className="nav-link sidebar__link" to={globalLinks.home} onClick={onNavigateTo(globalLinks.home)}>
              <DashboardIcon className='align-text-bottom mr-2' size={globalSizes.icon.default} />
              Dashboard <span className="sr-only">(current)</span>
            </NavLink>
          </li>
          {
            renderRouteIfOneOfExist(
              userCapabilities,
              ["manage_profiles", "edit_admins", "delete_admins", "read_admins"],
              <li className="nav-item">
                <NavLink to={globalLinks.account.root} className="nav-link sidebar__link" onClick={onNavigateTo(globalLinks.account.profiles)}>
                  <Settings className='align-text-bottom mr-2' size={globalSizes.icon.default} />
                  {uiTexts.global.sidebar.accounts.root} <span className="sr-only">(current)</span>
                </NavLink>
                <ul className="nav sub-nav pl-3">
                  {
                    renderRouteIfAllowedTo(
                      userCapabilities,
                      ["manage_profiles"],
                      <>
                        <li className="nav-item">
                          <NavLink className="nav-link sidebar__link" to={globalLinks.account.profiles} onClick={onToggleMobileMenu}>
                            <Users className='align-text-bottom mr-2' size={globalSizes.icon.default} />
                            {uiTexts.global.sidebar.accounts.profiles} <span className="sr-only">(current)</span>
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink className="nav-link sidebar__link" to={globalLinks.account.invoices} onClick={onToggleMobileMenu}>
                            <List className='align-text-bottom mr-2' size={globalSizes.icon.default} />
                            {uiTexts.global.sidebar.accounts.invoices} <span className="sr-only">(current)</span>
                          </NavLink>
                        </li>
                      </>
                    )
                  }
                  {
                    renderRouteIfAllowedTo(
                      userCapabilities,
                      ["edit_admins", "delete_admins", "read_admins"],
                      <li className="nav-item">
                        <NavLink className="nav-link sidebar__link" to={globalLinks.account.manageAdmins.root} onClick={onToggleMobileMenu}>
                          <List className='align-text-bottom mr-2' size={globalSizes.icon.default} />
                          {uiTexts.global.sidebar.accounts.manageAdmins.root} <span className="sr-only">(current)</span>
                        </NavLink>
                      </li>
                    )
                  }
                </ul>
              </li>
            )
          }
          {
            renderRouteIfOneOfExist(
              userCapabilities,
              ["read_none_paid_libraries", "read_paid_libraries", "read_delivered_libraries", "read_none_published_libraries"],
              <li className="nav-item">
                <NavLink className="nav-link sidebar__link" to={globalLinks.library.root} onClick={onNavigateTo(globalLinks.library.pendingReleases)}>
                  <Music className='align-text-bottom mr-2' size={globalSizes.icon.default} />
                  {uiTexts.global.sidebar.productions.root} <span className="sr-only">(current)</span>
                </NavLink>
                <ul className="nav sub-nav pl-3">
                  {
                    renderRouteIfAllowedTo(
                      userCapabilities,
                      ["read_none_paid_libraries", "read_paid_libraries", "read_delivered_libraries"],
                      <>
                        <li className="nav-item">
                          <NavLink className="nav-link sidebar__link" to={globalLinks.library.pendingReleases} onClick={onToggleMobileMenu}>
                            <Activity className='align-text-bottom mr-2' size={globalSizes.icon.default} />
                            {uiTexts.global.sidebar.productions.pending} <span className="sr-only">(current)</span>
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink className="nav-link sidebar__link" to={globalLinks.library.paidReleases} onClick={onToggleMobileMenu}>
                            <Sliders className='align-text-bottom mr-2' size={globalSizes.icon.default} />
                            {uiTexts.global.sidebar.productions.paid} <span className="sr-only">(current)</span>
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink className="nav-link sidebar__link" to={globalLinks.library.deliveredReleases} onClick={onToggleMobileMenu}>
                            <Headphones className='align-text-bottom mr-2' size={globalSizes.icon.default} />
                            {uiTexts.global.sidebar.productions.delivered} <span className="sr-only">(current)</span>
                          </NavLink>
                        </li>
                      </>
                    )
                  }
                  {
                    renderRouteIfAllowedTo(
                      userCapabilities,
                      ["read_none_published_libraries"],
                      <li className="nav-item">
                        <NavLink className="nav-link sidebar__link" to={globalLinks.library.unpublishedReleases} onClick={onToggleMobileMenu}>
                          <ZapOff className='align-text-bottom mr-2' size={globalSizes.icon.default} />
                          {uiTexts.global.sidebar.productions.unpublishedReleases} <span className="sr-only">(current)</span>
                        </NavLink>
                      </li>
                    )
                  }
                </ul>
              </li>
            )
          }
          {
            renderRouteIfOneOfExist(
              userCapabilities,
              ["read_pending_payout", "read_paid_payout", "read_unpaid_accounts"],
              <li className="nav-item">
                <NavLink to={globalLinks.account.payouts} className="nav-link sidebar__link" onClick={onNavigateTo(globalLinks.account.pendingPayouts)}>
                  <DollarSign className='align-text-bottom mr-2' size={globalSizes.icon.default} />
                  {uiTexts.global.sidebar.payouts.root} <span className="sr-only">(current)</span>
                </NavLink>
                <ul className="nav sub-nav pl-3">
                  {
                    renderRouteIfAllowedTo(
                      userCapabilities,
                      ["read_pending_payout"],
                      <li className="nav-item">
                        <NavLink className="nav-link sidebar__link" to={globalLinks.account.pendingPayouts} onClick={onToggleMobileMenu}>
                          <XCircle className='align-text-bottom mr-2' size={globalSizes.icon.default} />
                          {uiTexts.global.sidebar.payouts.pending} <span className="sr-only">(current)</span>
                        </NavLink>
                      </li>
                    )
                  }
                  {
                    renderRouteIfAllowedTo(
                      userCapabilities,
                      ["read_paid_payout"],
                      <li className="nav-item">
                        <NavLink className="nav-link sidebar__link" to={globalLinks.account.paidPayouts} onClick={onToggleMobileMenu}>
                          <CheckCircle className='align-text-bottom mr-2' size={globalSizes.icon.default} />
                          {uiTexts.global.sidebar.payouts.paid} <span className="sr-only">(current)</span>
                        </NavLink>
                      </li>
                    )
                  }
                  {
                    renderRouteIfAllowedTo(
                      userCapabilities,
                      ["read_unpaid_accounts"],
                      <li className="nav-item">
                        <NavLink className="nav-link sidebar__link" to={globalLinks.account.unpayedAccounts} onClick={onToggleMobileMenu}>
                          <CheckCircle className='align-text-bottom mr-2' size={globalSizes.icon.default} />
                          {uiTexts.global.sidebar.payouts.unpayedAccounts} <span className="sr-only">(current)</span>
                        </NavLink>
                      </li>
                    )
                  }
                </ul>
              </li>
            )
          }
          {
            renderRouteIfOneOfExist(
              userCapabilities,
              ["read_reports", "edit_reports"],
              <li className="nav-item">
                <NavLink className="nav-link sidebar__link" to={globalLinks.reporting.list} onClick={onNavigateTo(globalLinks.reporting.byPlatform)}>
                  <BarChart2 className='align-text-bottom mr-2' size={globalSizes.icon.default} />
                  {uiTexts.global.sidebar.reports} <span className="sr-only">(current)</span>
                </NavLink>
                <ul className="nav sub-nav pl-3">
                  {
                    renderRouteIfAllowedTo(
                      userCapabilities,
                      ["read_reports"],
                      <>
                        <li className="nav-item">
                          <NavLink exact className="nav-link sidebar__link" to={globalLinks.reporting.byPlatform} onClick={onToggleMobileMenu}>
                            <List className='align-text-bottom mr-2' size={globalSizes.icon.default} />
                            {uiTexts.global.sidebar.reporting.byPlatform} <span className="sr-only">(current)</span>
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink exact className="nav-link sidebar__link" to={globalLinks.reporting.byYoutube} onClick={onToggleMobileMenu}>
                            <List className='align-text-bottom mr-2' size={globalSizes.icon.default} />
                            {uiTexts.global.sidebar.reporting.byYoutube} <span className="sr-only">(current)</span>
                          </NavLink>
                        </li>
                      </>
                    )
                  }
                  {
                    renderRouteIfAllowedTo(
                      userCapabilities,
                      ["edit_reports"],
                      <>
                        <li className="nav-item">
                          <NavLink className="nav-link sidebar__link" to={globalLinks.reporting.platform} onClick={onToggleMobileMenu}>
                            <Disc className='align-text-bottom mr-2' size={globalSizes.icon.default} />
                            {uiTexts.global.sidebar.reporting.platform} <span className="sr-only">(current)</span>
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink className="nav-link sidebar__link" to={globalLinks.reporting.youtube} onClick={onToggleMobileMenu}>
                            <Youtube className='align-text-bottom mr-2' size={globalSizes.icon.default} />
                            {uiTexts.global.sidebar.reporting.youtube} <span className="sr-only">(current)</span>
                          </NavLink>
                        </li>
                      </>
                    )
                  }
                </ul>
              </li>
            )
          }
          {
            renderRouteIfOneOfExist(
              userCapabilities,
              ["read_coupon", "edit_coupon", "read_smartlink", "read_subscriptions"],
              <li className="nav-item">
                <NavLink className="nav-link sidebar__link" to={globalLinks.services.root} onClick={onNavigateTo(globalLinks.services.promoCode.list)}>
                  <BarChart2 className='align-text-bottom mr-2' size={globalSizes.icon.default} />
                  {uiTexts.global.sidebar.services.root} <span className="sr-only">(current)</span>
                </NavLink>
                <ul className="nav sub-nav pl-3">
                  {
                    renderRouteIfAllowedTo(
                      userCapabilities,
                      ["read_coupon"],
                      <li className="nav-item">
                        <NavLink className="nav-link sidebar__link" to={globalLinks.services.promoCode.list} onClick={onToggleMobileMenu} exact>
                          <List className='align-text-bottom mr-2' size={globalSizes.icon.default} />
                          {uiTexts.global.sidebar.services.promoCode.list} <span className="sr-only">(current)</span>
                        </NavLink>
                      </li>
                    )
                  }
                  {
                    renderRouteIfAllowedTo(
                      userCapabilities,
                      ["edit_coupon"],
                      <li className="nav-item">
                        <NavLink className="nav-link sidebar__link" to={globalLinks.services.promoCode.add} onClick={onToggleMobileMenu}>
                          <List className='align-text-bottom mr-2' size={globalSizes.icon.default} />
                          {uiTexts.global.sidebar.services.promoCode.add} <span className="sr-only">(current)</span>
                        </NavLink>
                      </li>
                    )
                  }
                  {
                    renderRouteIfAllowedTo(
                      userCapabilities,
                      ["read_smartlink"],
                      <>
                        <li className="nav-item">
                          <NavLink className="nav-link sidebar__link" to={`${globalLinks.services.smartlink.item}/pending`} onClick={onToggleMobileMenu}>
                            <List className='align-text-bottom mr-2' size={globalSizes.icon.default} />
                            {uiTexts.global.sidebar.services.smartlink.pending} <span className="sr-only">(current)</span>
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink className="nav-link sidebar__link" to={`${globalLinks.services.smartlink.item}/enabled`} onClick={onToggleMobileMenu}>
                            <List className='align-text-bottom mr-2' size={globalSizes.icon.default} />
                            {uiTexts.global.sidebar.services.smartlink.enabled} <span className="sr-only">(current)</span>
                          </NavLink>
                        </li>
                      </>
                    )
                  }
                  {
                    renderRouteIfAllowedTo(
                      userCapabilities,
                      ["read_subscriptions"],
                      <li className="nav-item">
                        <NavLink className="nav-link sidebar__link" to={`${globalLinks.services.subscriptions.list}`} onClick={onToggleMobileMenu}>
                          <List className='align-text-bottom mr-2' size={globalSizes.icon.default} />
                          {uiTexts.global.sidebar.services.subscription.list} <span className="sr-only">(current)</span>
                        </NavLink>
                      </li>
                    )
                  }
                </ul>
              </li>
            )
          }
          {
            renderRouteIfAllowedTo(
              userCapabilities,
              ["edit_tools"],
              <li className="nav-item">
                <NavLink className="nav-link sidebar__link" to={globalLinks.constracts.root} onClick={onNavigateTo(globalLinks.constracts.root)}>
                  <FilePlus className='align-text-bottom mr-2' size={globalSizes.icon.default} />
                  {uiTexts.contracts.custom.title} <span className="sr-only">(current)</span>
                </NavLink>
              </li>
            )
          }
          {
            renderRouteIfOneOfExist(
              userCapabilities,
              ["read_platforms_stats", "read_youtube_stats"],
              <li className="nav-item">
                <NavLink className="nav-link sidebar__link" to={globalLinks.accounting.root} onClick={onNavigateTo(globalLinks.accounting.default)}>
                  <BarChart2 className='align-text-bottom mr-2' size={globalSizes.icon.default} />
                  {uiTexts.accountingStats.home.title} <span className="sr-only">(current)</span>
                </NavLink>
                <ul className="nav sub-nav pl-3">
                  {
                    renderRouteIfAllowedTo(
                      userCapabilities,
                      ["read_platforms_stats"],
                      <li className="nav-item">
                        <NavLink className="nav-link sidebar__link" to={globalLinks.accounting.platform} onClick={onToggleMobileMenu} exact>
                          <Youtube className='align-text-bottom mr-2' size={globalSizes.icon.default} />
                          {uiTexts.global.sidebar.accountingStats.platform} <span className="sr-only">(current)</span>
                        </NavLink>
                      </li>
                    )
                  }
                  {
                    renderRouteIfAllowedTo(
                      userCapabilities,
                      ["read_youtube_stats"],
                      <li className="nav-item">
                        <NavLink className="nav-link sidebar__link" to={globalLinks.accounting.youtube} onClick={onToggleMobileMenu} exact>
                          <Disc className='align-text-bottom mr-2' size={globalSizes.icon.default} />
                          {uiTexts.global.sidebar.accountingStats.youtube} <span className="sr-only">(current)</span>
                        </NavLink>
                      </li>
                    )
                  }
                </ul>
              </li>
            )
          }
          {
            renderRouteIfOneOfExist(
              userCapabilities,
              ["read_tools", "edit_tools"],
              <li className="nav-item mb-4">
                <NavLink className="nav-link sidebar__link" to={globalLinks.tools.root} onClick={onNavigateTo(globalLinks.tools.editIsrcOrBarcode)}>
                  <Tool className='align-text-bottom mr-2' size={globalSizes.icon.default} />
                  {uiTexts.global.sidebar.tools.root} <span className="sr-only">(current)</span>
                </NavLink>
                <ul className="nav sub-nav pl-3">
                  {
                    renderRouteIfOneOfExist(
                      userCapabilities,
                      ["edit_tools", "read_tools"],
                      <>
                        <li className="nav-item">
                          <NavLink className="nav-link sidebar__link" to={globalLinks.tools.ticket.opened} onClick={onToggleMobileMenu} exact>
                            {uiTexts.global.sidebar.tools.ticket.opened} <span className="sr-only">(current)</span>
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink className="nav-link sidebar__link" to={globalLinks.tools.ticket.closed} onClick={onToggleMobileMenu} exact>
                            {uiTexts.global.sidebar.tools.ticket.closed} <span className="sr-only">(current)</span>
                          </NavLink>
                        </li>
                      </>
                    )
                  }
                  {
                    renderRouteIfAllowedTo(
                      userCapabilities,
                      ["edit_tools"],
                      <>
                        <li className="nav-item">
                          <NavLink className="nav-link sidebar__link" to={globalLinks.tools.editIsrcOrBarcode} onClick={onToggleMobileMenu} exact>
                            {uiTexts.global.sidebar.tools.editIsrcOrBarcode} <span className="sr-only">(current)</span>
                          </NavLink>
                        </li>
                      </>
                    )
                  }
                  {
                    renderRouteIfAllowedTo(
                      userCapabilities,
                      ["read_tools"],
                      <>
                        <li className="nav-item">
                          <NavLink className="nav-link sidebar__link" to={globalLinks.tools.isrcRedundancy} onClick={onToggleMobileMenu} exact>
                            {uiTexts.global.sidebar.tools.redundancy} <span className="sr-only">(current)</span>
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink className="nav-link sidebar__link" to={globalLinks.tools.namingRedundancy} onClick={onToggleMobileMenu} exact>
                            {uiTexts.global.sidebar.tools.namingRedundancy} <span className="sr-only">(current)</span>
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink className="nav-link sidebar__link" to={globalLinks.tools.emptyISRCBarcode} onClick={onToggleMobileMenu} exact>
                            {uiTexts.global.sidebar.tools.emptyIsrcBarcode} <span className="sr-only">(current)</span>
                          </NavLink>
                        </li>
                      </>
                    )
                  }
                </ul>
              </li>
            )
          }
          <li className="nav-item">
            <NavLink to={'/signout'} onClick={onSignOut} className="nav-link sidebar__link">
              <LogOut className='align-text-bottom mr-2' size={globalSizes.icon.default} />
              {uiTexts.global.signout}
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  )
}
