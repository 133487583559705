import React from 'react';
import uiTexts from '../../config/text';
import PageLayout from '../../libs/PageLayout';
import BoxLink from '../../components/BoxLink';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './style.css';

export default function Home() {
  return (
    <PageLayout className='page__bloc pt-3 pt-md-5 pb-3 pb-md-5 main__block' title={uiTexts.home.title}>
      <Row className='mb-3 mb-md-4 d-flex'>
        <Col sm={12} md={4} className='pl-0_75rem pr-0_75rem mb-3 mb-md-0'>
          <BoxLink
            title={uiTexts.home.boxes.accounts.title}
            href={uiTexts.home.boxes.accounts.action.url}
            linkLabel={uiTexts.home.boxes.accounts.action.label}>
            <p className='mb-0'>
              {uiTexts.home.boxes.accounts.content}
            </p>
          </BoxLink>
        </Col>
        <Col sm={12} md={4} className='pl-0_75rem pr-0_75rem mb-3 mb-md-0'>
          <BoxLink
            title={uiTexts.home.boxes.releases.title}
            href={uiTexts.home.boxes.releases.action.url}
            className='border_color_light-blue border_radius_3'
            linkClassName='btn-success'
            linkLabel={uiTexts.home.boxes.releases.action.label}>
            <p className='mb-0'>
              {uiTexts.home.boxes.releases.content}
            </p>
          </BoxLink>
        </Col>
        <Col sm={12} md={4} className='pl-0_75rem pr-0_75rem mb-3 mb-md-0'>
          <BoxLink
            title={uiTexts.home.boxes.payouts.title}
            href={uiTexts.home.boxes.payouts.action.url}
            className='border_color_light-blue border_radius_3'
            linkClassName='btn-success'
            linkLabel={uiTexts.home.boxes.payouts.action.label}>
            <p className='mb-0'>
              {uiTexts.home.boxes.payouts.content}
            </p>
          </BoxLink>
        </Col>
      </Row>
      <Row className='mb-3 mb-md-4 d-flex'>
        <Col sm={12} md={6} className='pl-0_75rem pr-0_75rem mb-3 mb-md-0'>
          <BoxLink
            title={uiTexts.home.boxes.reportings.add.title}
            href={uiTexts.home.boxes.reportings.add.action.url}
            linkClassName='btn-success'
            linkLabel={uiTexts.home.boxes.reportings.add.action.label}>
            <p className='mb-0'>
              {uiTexts.home.boxes.reportings.add.content}
            </p>
          </BoxLink>
        </Col>
        <Col sm={12} md={6} className='pl-0_75rem pr-0_75rem mb-3 mb-md-0'>
          <BoxLink
            title={uiTexts.home.boxes.reportings.all.title}
            href={uiTexts.home.boxes.reportings.all.action.url}
            className='border_color_light-blue border_radius_3'
            linkLabel={uiTexts.home.boxes.reportings.all.action.label}>
            <p className='mb-0'>
              {uiTexts.home.boxes.reportings.all.content}
            </p>
          </BoxLink>
        </Col>
      </Row>
    </PageLayout>
  )
}
