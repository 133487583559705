import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';
import GradientButton from '../../components/GradientButton';
import countriesByContinents from '../../config/data/countriesByContinents';
import { ArrowLeft } from 'react-feather';
import { GlobalReducerActionType } from '../../types/shared';
import uiTexts from '../../config/text';
import globalLinks from '../../config/links';
import globalSizes from '../../config/sizes';

interface ProfileFormPropsTypes {
  onSubmit: (evnt: React.FormEvent<HTMLFormElement>) => Promise<void>;
  state: any;
  onChange: (evnt: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeRole: (evnt: React.ChangeEvent<HTMLInputElement>) => void;
}
function ProfileForm({ onSubmit, onChange, onChangeRole, state }: ProfileFormPropsTypes) {
  return (
    <Form onSubmit={onSubmit}>
      <Form.Row>
        <Col xs={12} sm={6}>
          <Form.Group className='mb-2' controlId='company'>
            <Form.Label className='mb-1'>{uiTexts.account.profil.company}</Form.Label>
            <Form.Control size='lg' onChange={onChange} defaultValue={state.data.company} name="company" placeholder={uiTexts.account.profil.company} />
          </Form.Group>
        </Col>
        <Col xs={12} sm={6}>
          <Form.Group className='mb-2' controlId='city'>
            <Form.Label className='mb-1'>{uiTexts.account.profil.city}</Form.Label>
            <Form.Control size='lg' onChange={onChange} defaultValue={state.data.city} isInvalid={!!state.errors.city} name="city" placeholder={uiTexts.account.profil.city} />
            {!!state.errors.city &&
              <Form.Control.Feedback type="invalid">
                {state.errors.city}
              </Form.Control.Feedback>}
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} sm={6}>
          <Form.Group className='mb-2' controlId='tva'>
            <Form.Label className='mb-1'>{uiTexts.account.profil.tva}</Form.Label>
            <Form.Control size='lg' onChange={onChange} value={state.data.tax || ''} name="tax" placeholder={uiTexts.account.profil.tva} />
            {!!state.errors.tax &&
              <Form.Control.Feedback className='d-block' type="invalid">
                {state.errors.tax}
              </Form.Control.Feedback>}
          </Form.Group>
        </Col>
        <Col xs={12} sm={6}>
          <Form.Group className='mb-2' controlId='postcode'>
            <Form.Label className='mb-1'>{uiTexts.account.profil.postcode}</Form.Label>
            <Form.Control size='lg' onChange={onChange} defaultValue={state.data.postcode} isInvalid={!!state.errors.postcode} name="postcode" placeholder={uiTexts.account.profil.postcode} />
            {!!state.errors.postcode &&
              <Form.Control.Feedback type="invalid">
                {state.errors.postcode}
              </Form.Control.Feedback>}
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} sm={6}>
          <Form.Group className='mb-2' controlId='first-name'>
            <Form.Label className='mb-1'>{uiTexts.signup.form.fname}*</Form.Label>
            <Form.Control size='lg' onChange={onChange} value={state.data.fname} isInvalid={!!state.errors.fname} name="fname" placeholder={uiTexts.signup.form.fname} />
            {!!state.errors.fname &&
              <Form.Control.Feedback type="invalid">
                {state.errors.fname}
              </Form.Control.Feedback>}
          </Form.Group>
        </Col>
        <Col xs={12} sm={6}>
          <Form.Group className='mb-2' controlId='last-name'>
            <Form.Label className='mb-1'>{uiTexts.signup.form.lname}*</Form.Label>
            <Form.Control size='lg' onChange={onChange} value={state.data.lname} isInvalid={!!state.errors.lname} name="lname" placeholder={uiTexts.signup.form.lname} />
            {!!state.errors.lname &&
              <Form.Control.Feedback type="invalid">
                {state.errors.lname}
              </Form.Control.Feedback>}
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} sm={6}>
          <Form.Group className='mb-2' controlId='country'>
            <Form.Label className='mb-1'>{uiTexts.account.profil.country}*</Form.Label>
            <Form.Control size='lg' onChange={onChange} name='country' as="select" className='pt-2' value={state.data.country || '-1'} isInvalid={!!state.errors?.country}>
              <option value='-1' disabled>{uiTexts.account.profil.country}</option>
              {Object.keys(countriesByContinents).map((continent: string) => {
                return countriesByContinents[continent].map((country: string) => (
                  <option key={country} value={country}>{country}</option>
                ));
              })}
            </Form.Control>
            {!!state.errors.country &&
              <Form.Control.Feedback type="invalid">
                {state.errors.country}
              </Form.Control.Feedback>}
          </Form.Group>
        </Col>
        <Col xs={12} sm={6}>
          <Form.Group className='mb-2' controlId='email'>
            <Form.Label className='mb-1'>{uiTexts.signup.form.email}</Form.Label>
            <Form.Control size='lg' onChange={onChange} value={state.data.email} isInvalid={!!state.errors.email} name="email" placeholder={uiTexts.signup.form.email} />
            {!!state.errors.email &&
              <Form.Control.Feedback type="invalid">
                {state.errors.email}
              </Form.Control.Feedback>}
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} sm={6}>
          <Form.Group className='mb-2' controlId='address'>
            <Form.Label className='mb-1'>{uiTexts.account.profil.address}</Form.Label>
            <Form.Control size='lg' onChange={onChange} defaultValue={state.data.address} isInvalid={!!state.errors.address} name="address" placeholder={uiTexts.account.profil.address} />
            {!!state.errors.address &&
              <Form.Control.Feedback type="invalid">
                {state.errors.address}
              </Form.Control.Feedback>}
          </Form.Group>
        </Col>
        <Col xs={12} sm={6}>
          <Form.Group className='mb-2' controlId='address2'>
            <Form.Label className='mb-1'>{uiTexts.account.profil.address2}</Form.Label>
            <Form.Control size='lg' onChange={onChange} defaultValue={state.data.address2} name="address2" placeholder={uiTexts.account.profil.address2} />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} sm={6}>
          <Form.Group controlId="timezone">
            <Form.Label>{uiTexts.account.profil.timezone}</Form.Label>
            <Form.Control size='lg' onChange={onChange} name='timezone' as="select" className='pt-2' value={state.data?.timezone || '-1'} isInvalid={!!state.errors?.timezone}>
              <option value='-1' disabled>{uiTexts.account.profil.timezone}</option>
              <option value='GMT -6'>GMT -6</option>
              <option value='GMT -5'>GMT -5</option>
              <option value='GMT -4'>GMT -4</option>
              <option value='GMT -1'>GMT -1</option>
              <option value='GMT'>GMT</option>
              <option value='GMT +1'>GMT +1</option>
              <option value='GMT +2'>GMT +2</option>
              <option value='GMT +3'>GMT +3</option>
              <option value='GMT +4'>GMT +4</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col xs={12} sm={6}>
          <Form.Group controlId="state">
            <Form.Label>{uiTexts.account.profil.state} {state.data?.state}</Form.Label>
            <Form.Control size='lg' onChange={onChange} name='state' as="select" className='pt-2' value={state.data?.state || '-1'} isInvalid={!!state.errors?.state}>
              <option value='-1' disabled>{uiTexts.account.profil.state}</option>
              <option value='ENABLED'>Activer</option>
              <option value='DISABLED'>Desactiver</option>
              <option value='HOLD'>Bloquer</option>
            </Form.Control>
            {!!state.errors.state &&
              <Form.Control.Feedback type="invalid">
                {state.errors.state}
              </Form.Control.Feedback>}
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12}>
          <Form.Group className='mb-2' controlId='income'>
            <Form.Label className='mb-1'>{uiTexts.account.profil.income}</Form.Label>
            <Form.Control size='lg' onChange={onChange} defaultValue={state.data.income} name="income" placeholder={uiTexts.account.profil.income} isInvalid={!!state.errors.income} />
            {!!state.errors.income &&
              <Form.Control.Feedback type="invalid">
                {state.errors.income}
              </Form.Control.Feedback>}
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} sm={6}>
          <Form.Group className='mb-2' controlId='libraryIncomePercentile'>
            <Form.Label className='mb-1'>{uiTexts.account.profil.libraryIncomePercentile}</Form.Label>
            <Form.Control onChange={onChange} defaultValue={state.data.libraryIncomePercentile} name="libraryIncomePercentile" placeholder={uiTexts.account.profil.libraryIncomePercentile} isInvalid={!!state.errors.libraryIncomePercentile} size='lg' type='number' step='0.01' />
            {!!state.errors.libraryIncomePercentile &&
              <Form.Control.Feedback type="invalid">
                {state.errors.libraryIncomePercentile}
              </Form.Control.Feedback>}
          </Form.Group>
        </Col>
        <Col xs={12} sm={6}>
          <Form.Group className='mb-2' controlId='youtubeChannelIncomePercentile'>
            <Form.Label className='mb-1'>{uiTexts.account.profil.youtubeChannelIncomePercentile}</Form.Label>
            <Form.Control onChange={onChange} defaultValue={state.data.youtubeChannelIncomePercentile} name="youtubeChannelIncomePercentile" placeholder={uiTexts.account.profil.youtubeChannelIncomePercentile} isInvalid={!!state.errors.youtubeChannelIncomePercentile} size='lg' type='number' step='0.01' />
            {!!state.errors.youtubeChannelIncomePercentile &&
              <Form.Control.Feedback type="invalid">
                {state.errors.youtubeChannelIncomePercentile}
              </Form.Control.Feedback>}
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group controlId="memo">
            <Form.Label>{uiTexts.account.profil.memo}</Form.Label>
            <Form.Control onChange={onChange} name='memo' as="textarea" defaultValue={state.data.memo} className='pt-2' rows={3} isInvalid={!!state.errors.memo} />
            {!!state.errors.memo &&
              <Form.Control.Feedback type="invalid">
                {state.errors.memo}
              </Form.Control.Feedback>}
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Switch
              id="role"
              label={uiTexts.account.profil.isLabel}
              value={state.data.role}
              checked={state.data.role === 'LABEL'}
              name='role'
              onChange={onChangeRole}
              isInvalid={!!state.errors.role} />
            {!!state.errors.role &&
              <Form.Control.Feedback className='d-block' type="invalid">
                {state.errors.role}
              </Form.Control.Feedback>}
          </Form.Group>
        </Col>
      </Form.Row>
      <div className="d-flex justify-content-end">
        <Link to={globalLinks.account.profiles} className='btn btn-light mr-2 d-flex align-items-center'>
          <ArrowLeft size={globalSizes.icon.default} className='mr-2' />
          {uiTexts.global.cancel}
        </Link>
        <GradientButton size='lg' disabled={!!state.loading} className='text-white pl-4 pr-4' type="submit">
          {!!state.loading
            ? uiTexts.global.loading
            : uiTexts.global.save}
        </GradientButton>
      </div>
    </Form>
  );
}

export default ProfileForm;